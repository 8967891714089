import '../node_modules/jquery-zoom/jquery.zoom';

jQuery(function ($) {
    let imageContainer = $( '.main-image-popup' );

    $( imageContainer ).magnificPopup({
        type: 'image'
    });

    startZoom( imageContainer )

    $( window ).on( 'load resize', function() {
        startZoom( imageContainer );
    });


    function startZoom( elementToZoom ) {
        if ( $( window ).width() > 735 ) {
            $( elementToZoom ).zoom({
                duration: 400
            });
        }
    }
});